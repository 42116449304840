import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import Loader from "../../components/Loader";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { del, get, post, put, uploadS3 } from '../../helper/api_helper';
import * as Utils from "../../Utils";
import moment from "moment";
import Header from "../../layouts/Header";

export default function Banner() {
  const user = useSelector(state => state.user);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [file, setFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const cropper = useRef(null);

  useEffect(() => {
    // console.log('props on user table', props)
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
    }
  }, [isAddForm]);

  const getData = () => {
    let url = "banner/list/admin";
    get(url, { token: user?.token })
      .then(json => {
        // console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      // {
      //   name: "Position",
      //   cell: (row, index) => <>
      //     <Draggable key={row._id} draggableId={row._id} index={index}>
      //       {(provided, snapshot) => (
      //         <div
      //           ref={provided.innerRef}
      //           {...provided.draggableProps}
      //           {...provided.dragHandleProps}
      //         >
      //           <i className="ri-menu-line" style={{ fontSize: 20, color: Utils.themeColor }}></i>
      //         </div>
      //       )}
      //     </Draggable>
      //   </>,
      //   maxWidth: '50px'
      // },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 100, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: '130px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            className={`btn_status ${row?.isActive && 'active'}`}
          >
            <p className="text-white" style={{ fontSize: 16, marginBottom: 0 }}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </p>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          {checkPermission("banner", "update") ?
            <Button onClick={() => handleUpdateProj(row)}
              title={"Edit"}
              style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', backgroundColor: Utils.themeColor, }}><i className="ri-edit-fill" style={{ fontSize: 16 }}></i></Button>
            : null}
          {checkPermission("banner", "delete") ?
            <Button onClick={() => handleDeleteProj(row)}
              title={"Delete"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-delete-bin-6-line" style={{ fontSize: 16 }}></i></Button>
            : null}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
    ])
  }, [data, user]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    if (checkPermission("banner", "update")) {
      setCurrentData({ ...item, actionType: 'Status' });
      setConfirm(true);
    } else
      toast.error("You are not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentData({ ...proj, actionType: 'Delete' });
    setConfirm(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      bannerId: currentData?._id,
      token: user?.token
    }
    let url = "banner/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setConfirm(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onProgress = (p) => {
    console.log(p);
  }

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      token: user?.token,
    }
    setLoading(true);
    if (file.substring(0, 4) == "blob") {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
      const temp = await resizeFile(obj);
      const imageRes = await uploadS3("banner", temp, "webp", onProgress);
      body.image = imageRes?.data;
    }
    if (currentData) {
      body.bannerId = currentData?._id;
      put("banner/update", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      post("banner/add", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.messgae);
            setIsAddForm(false);
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error('Something Went Wrong!');
          console.log('error while adding banner', error);
        })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("banner/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const checkPermission = (to, type) => {
    if (user?.role == "Admin")
      return true;
    else if (user?.role == "Sub") {
      let permission = user?.permissions;
      let find = permission.find(x => Object.keys(x).includes(to))
      if (find)
        return find[to][type]
    } else {
      return false;

    }
  }

  return (
    <React.Fragment>
      <Header />
      <Loader visible={loading} />
      <ConfirmModal
        show={confirm}
        onConfirm={onDelete}
        onCloseClick={() => setConfirm(false)}
        data={currentData}
      />
      <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 540 }}>
        <ModalBody>
          <h5>{currentData ? 'Update' : 'Add'} Banner</h5>
          <div className="me-3 position-relative">
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >

              {file ?
                <>
                  {file?.substring(0, 4) == "blob" ?
                    <Cropper
                      style={{ height: 'auto', width: 500 }}
                      aspectRatio={1024 / 500}
                      preview=".img-preview"
                      guides={true}
                      src={file}
                      ref={cropper}
                    />
                    :
                    <div style={{ width: 500, position: 'relative' }}>
                      <img
                        src={file}
                        alt=""
                        style={{ width: 500, height: 'auto' }}
                      // onClick={(e) => imageInput.current.click(e)}
                      />
                    </div>
                  }
                  <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -22, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                    onClick={() => { setFile(null); }}
                  >
                    <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                  </div>
                </>
                :
                <div className="mt-2">
                  <AvField
                    name="image"
                    type="file"
                    label="Image *"
                    onChange={(e) => onChangeFile(e)}
                    required
                  />
                </div>
              }
              <Row>
                <div className="text-center mt-4 d-flex justify-content-around">
                  <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', width: "40%" }}
                    onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                  >
                    Cancel
                  </Button>
                  {currentData ?
                    <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', width: "40%" }}>
                      Update Banner
                    </Button>
                    :
                    <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', width: "40%" }}>
                      Add Banner
                    </Button>
                  }
                </div>
              </Row>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
      <Card>
        <CardBody>
          <div className="main main-app p-3 p-lg-4">
            <Row>
              <Col>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">Banners</CardTitle>
                    <CardSubtitle className="mb-3">
                      Banners you have added before, All <code>banners </code>will reflect on your <code>Website and App.</code>
                    </CardSubtitle>
                  </div>
                  {checkPermission("banner", "write") ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                    : null}
                </div>
                <Row>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              }
                            }}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Row>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment >
  )
}