import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, download, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import moment from 'moment';

export default function Users() {
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [receiptModal, setReceiptModal] = useState(false);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'Cust Id',
                selector: row => row.custId,
                sortable: true,
                maxWidth: '110px'
            },
            {
                name: 'Cust Name',
                selector: row => row.name,
                sortable: true,
                // maxWidth: '150px',
            },
            {
                name: 'Plan',
                selector: row => row?.title,
                sortable: true,
                maxWidth: '150px',
            },
            {
                name: 'Amount',
                selector: row => row?.paid,
                sortable: true,
                maxWidth: '120px',
            },
            {
                name: 'Paid On',
                selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: '180px',
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleViewReceipt(row)}
                        title={"Receipt"}
                        className={`btn_status active`}
                    >
                        <i className='ri-image-line' style={{ fontSize: 20 }} />
                    </Button>
                </>,
                name: 'Receipt',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
            {
                cell: (row) => <>
                    <select className='form-select' value={row?.status} disabled={!checkPermission("paidUserRequests", "update")} onChange={(e) => handleStatusUpdate(row, e.target.value)}>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approve</option>
                        <option value="Rejected">Reject</option>
                    </select>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '150px'
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setCurrentData({ ...row, txn: true }); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    {/* {
                        checkPermission('city', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('city', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    } */}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
        ])
    }, [data, user]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("transaction/list", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                        if (currentData?._id) {
                            let found = res?.data?.find(x => x?._id == currentData?._id);
                            if (found)
                                setCurrentData(found);
                            else
                                setCurrentData({ ...currentData, txn: undefined });
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        setCurrentData({ ...row, actionType: "Status" });
        setConfirm(true);
    }

    const handleStatusUpdate = (row, val) => {
        if (checkPermission("paidUserRequests", "update")) {
            setCurrentData({ ...row, actionType: "PayStatus", to: val });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateKYCStatus = (row) => {
        setCurrentData({ ...row, actionType: "KYC" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                txnId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'PayStatus') {
                body = { ...body, status: currentData?.to }
                put("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;
        }
    }

    const handleViewReceipt = (row) => {
        setCurrentData(row);
        setReceiptModal(true);
    }

    const downloadCsv = () => {
        if (data?.length < 1)
            return;
        if (!loading) {
            setLoading(true);
            download("transaction/download", { token: user?.token })
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'users.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    // console.log("currentData", currentData)
    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?.userId}
                txn={currentData}
                setCurrentData={setCurrentData}
                setConfirm={setConfirm}
            />
            <Modal isOpen={receiptModal} centered={true} style={{ maxWidth: 800 }} toggle={() => setReceiptModal(false)}>
                <ModalBody className="py-3 px-5">
                    <Row>
                        <Col md={11}>
                        </Col>
                        <Col md={1} className=''>
                            <button
                                type="button"
                                className="btn btn-primary btn-lg d-flex justify-content-center"
                                onClick={() => setReceiptModal(false)}
                                style={{ width: 50 }}
                            >
                                <i className='ri-close-line' style={{ fontSize: 20, fontWeight: 'bold' }} />
                            </button>
                        </Col>
                        <Col lg={12} className='mt-3'>
                            <img src={currentData?.receipt} style={{ width: "100%", height: "auto" }} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={10}>
                            <CardBody>
                                <CardTitle><b>Users</b></CardTitle>
                                <CardSubtitle>Users who have Paid the amount for<code> plan.</code></CardSubtitle>
                            </CardBody>
                        </Col>
                        <Col md={2}>
                            <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                <Button type="button" onClick={downloadCsv} className="btn-sign" style={{ float: 'right' }}>Export CSV</Button>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}
