import PropTypes from 'prop-types'
import React from "react"
import * as Utils from '../Utils'
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { post } from '../helper/api_helper'
import { toast } from 'react-toastify'

const NotifyModal = (props) => {
  const { show, onCloseClick, data, user, setLoading } = props;

  const handleNotify = (e, v) => {
    console.log("sending notification", v);
    setLoading(true);
    let body = {
      ...v,
      deviceToken: [data?.deviceToken],
      token: user?.token
    }
    post("notification/send_to_user", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          onCloseClick();
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error("Something Went Wrong!");
      })
  }

  return (
    <Modal isOpen={show} centered={true}>
      <ModalBody className="py-3 px-5">
        <AvForm onValidSubmit={handleNotify}>
          <Row>
            <Col lg={12}>
              <div>
                {/* {actionType=='Status' ? 
              <img src={require("../../assets/images/stats.png")} style={{width:200, height:'auto'}} />
            : */}

                {/* } */}
                <h2><i
                  className="ri-notification-fill"
                  style={{ fontSize: 24, color: Utils.themeColor }}
                /> Notify {data?.name}</h2>
              </div>
            </Col>
            <Col md={12}>
              <div className='mt-4'>
                <AvField
                  name="title"
                  placeholder="Enter the notification title"
                  label="Title *"
                  required
                />
              </div>
              <div className='mt-4'>
                <AvField
                  name="body"
                  placeholder="Enter the notification message"
                  label="Message (100 words max)*"
                  type={"textarea"}
                  rows={4}
                  maxLength={100}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="text-center mt-5 w-100">
                <button
                  type="button"
                  className="btn btn-danger btn-lg me-2 w-100"
                  onClick={onCloseClick}
                >
                  Cancel
                </button>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-center mt-5 w-100">
                <button
                  type="submit"
                  className="btn btn-success btn-lg me-2 w-100"
                >
                  Notify
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default NotifyModal
