import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, post, put, uploadS3 } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import Loader from "../../components/Loader";
import moment from "moment-timezone";
import { useLocation, useParams } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import * as XLSX from 'xlsx';

export default function Users() {
    const location = useLocation();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [userColumns, setUserColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [currentTask, setCurrentTask] = useState(null);
    const [profileModal, setProfileModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [taskModal, setTaskModal] = useState(false);
    const [taskUploadModal, setTaskUploadModal] = useState(false);
    const [taskModalList, setTaskListModal] = useState(false);
    const [moneyModal, setMoneyModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [file, setFile] = useState(null);
    const [taskFile, setTaskFile] = useState(null);
    const [subId, setSubId] = useState("");
    const [userId, setUserId] = useState('');
    const [walletHistory, setWalletHistory] = useState([]);
    const [walletColumns, setWalletColumns] = useState([]);
    const [noBook, setNoBook] = useState(0);
    const [item, setItem] = useState(36);
    const [week, setWeek] = useState([
        "1st Week", "2nd Week", "3rd Week", "4th Week", "5th Week", "6th Week",
        "7th Week", "8th Week", "9th Week", "10th Week", "11th Week", "12th Week",
        "13th Week", "14th Week", "15th Week", "16th Week", "17th Week", "18th Week",
        "19th Week", "20th Week", "21st Week", "22nd Week", "23rd Week", "24th Week",
        "25th Week", "26th Week", "27th Week", "28th Week", "29th Week", "30th Week",
        "31st Week", "32nd Week", "33rd Week", "34th Week", "35th Week", "36th Week"
    ]);
    const [submissions, setSubmissions] = useState([]);

    // console.log('location', location)

    useEffect(() => {
        // console.log("user id on task assignment", location?.state?.userId);
        if (location?.state?.userId) {
            let id = String(location?.state?.userId);
            setUserId(id)
            getData(id);
            setProfileModal(false);
        }
    }, [location?.state]);

    useEffect(() => {
        if (moneyModal) {
            // console.log('currrent data', currentData?.tasks?._id);
            post("wallet/list", { taskId: currentData?.tasks?._id, type: "Credit", status: "Approved", token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setWalletHistory(res?.data);
                    } else {
                        setWalletHistory([]);
                    }
                })
                .catch(err => {
                    setWalletHistory([]);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }, [moneyModal]);

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Work Title',
                selector: row => row?.tasks ? row?.tasks?.title : "-",
                sortable: true,

            },
            {
                name: 'Week',
                selector: row => row?.tasks?.week,
                sortable: true,
                maxWidth: '140px'
            },
            {
                name: 'Books',
                selector: row => row?.tasks ? row?.tasks?.books : "-",
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Submissions',
                selector: row => row?.submittedCount,
                sortable: true,
                maxWidth: "140px"
            },
            {
                name: 'Assigned On',
                selector: row => moment.tz(row?.tasks?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setCurrentData(row); setTaskListModal(true) }}
                        title={"Tasks"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-list-check" style={{ fontSize: 20 }}></i></Button>
                    {checkPermission("taskAssignment", "update") ?
                        <Button onClick={() => { setCurrentData(row); setMoneyModal(true) }}
                            title={"Amount"}
                            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className={"ri-money-rupee-circle-line mx-1"} style={{ fontSize: 20 }}>₹</i></Button>
                        : null}
                    <Button onClick={() => { setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    {
                        checkPermission('taskAssignment', 'write') &&
                        <Button onClick={() => handleDupliProj(row)}
                            title={"Duplicate"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-file-copy-fill" style={{ fontSize: 20 }}></i></Button>
                    }
                    {
                        checkPermission('taskAssignment', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-edit-fill" style={{ fontSize: 20 }}></i></Button>
                    }
                    {
                        checkPermission('taskAssignment', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></i></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '380px'
            },
        ])
    }, [data]);

    useEffect(() => {
        setUserColumns([
            {
                name: 'Cust ID',
                selector: row => row?.userId,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                sortable: true,
                maxWidth: '150px'
            },
            {
                name: 'Email',
                selector: row => row?.email,
                sortable: true,
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                sortable: true,
                maxWidth: "120px"
            },
            {
                name: 'plan',
                selector: row => row?.plan,
                sortable: true,
                maxWidth: "100px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setUserId(row?.userId); setSearchModal(false); getData(row?.userId); setUsers([]); }}
                        title={"Select"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-check-line" style={{ fontSize: 20 }}></i></Button>
                    <Button onClick={() => { setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    {/* {
                        checkPermission('city', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('city', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    } */}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [users]);

    useEffect(() => {
        setWalletColumns([
            {
                name: 'Amount',
                selector: row => "₹ " + row?.amount,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Type',
                selector: row => row?.type,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Wallet Balance',
                selector: row => "₹ " + row?.balance,
                sortable: true,
            },
            {
                name: 'Credited At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            }
        ])
    }, [walletHistory]);

    useEffect(() => {
        if (noBook > 0 && noBook < 100) {
            let temp = new Array(Number(noBook)).fill({ bookName: "" });
            setSubmissions(temp);
        }
    }, [noBook]);

    useEffect(() => {
        if (!taskModal) {
            setCurrentData(null);
            setCurrentTask(null);
            setFile(null);
            setNoBook("");
            setSubmissions([]);
        }
    }, [taskModal]);

    useEffect(() => {
        if (!confirm) {
            setCurrentData(null);
            setCurrentTask(null);
            setFile(null);
            setNoBook("");
            setSubmissions([]);
        }
    }, [confirm]);

    const getData = (str) => {
        if (str) {
            // const upperCaseString = str?.toUpperCase();
            if (!loading) {
                setLoading(true);
                get("task/user?search=" + str, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setData(res?.data);
                            if (currentData?.tasks?._id) {
                                res?.data?.forEach((item) => {
                                    if (item?.tasks?._id == currentData?.tasks?._id)
                                        setCurrentData(item);
                                })
                            }
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                submissions,
                userId: currentData?._id,
                token: user?.token
            }
            if (file && file.substring(0, 4) == "blob") {
                const obj = await urltoFile(file, new Date().getTime() + '.webp', 'image/webp');
                const temp = await resizeFile(obj);
                const imageRes = await uploadS3("assignment", temp, "webp", onProgress);
                body.courierSlip = imageRes?.data;
            }
            if (currentTask?._id) {
                body.taskId = currentTask?._id;
                put("task", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            getData(userId);
                            setTaskModal(false);
                            setFile(null);
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("task", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            getData(userId);
                            setTaskModal(false);
                            setFile(null);
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleValidUpload = async (e, v) => {
        try {
            console.log("values", taskFile);
            if (!loading) {
                setLoading(true);
                let taskTemp = [];
                const response = await fetch(taskFile);
                const blob = await response.blob();
                const arrayBuffer = await blob.arrayBuffer();
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const data = XLSX.utils.sheet_to_json(worksheet);
                const keyMapping = {
                    'Book Name': 'bookName',
                    'No of Books': 'books',
                    'Task Title': 'title',
                    'Total Pages': 'totalPages',
                    'Week': 'week'
                };
                taskTemp = data.map((item) => {
                    const newItem = {};
                    Object.keys(item).forEach((key) => {
                        const newKey = keyMapping[key] || key;
                        newItem[newKey] = item[key];
                    });
                    return newItem;
                });
                let courierSlip = undefined;
                if (file && file.substring(0, 4) == "blob") {
                    const obj = await urltoFile(file, new Date().getTime() + '.webp', 'image/webp');
                    const temp = await resizeFile(obj);
                    const imageRes = await uploadS3("assignment", temp, "webp", onProgress);
                    courierSlip = imageRes?.data;
                }
                let tasks = [];
                taskTemp.forEach((item) => {
                    let already = tasks.findIndex(x => x?.title == item?.title);
                    if (already == -1)
                        tasks.push({
                            ...v,
                            courierSlip,
                            title: item?.title,
                            week: item?.week,
                            books: item?.books,
                            submissions: [{ bookName: item?.bookName, totalPages: item?.totalPages }],
                            userId: currentData?._id,
                            token: user?.token
                        })
                    else {
                        tasks[already].submissions.push({ bookName: item?.bookName, totalPages: item?.totalPages });
                    }
                })
                // console.log("values", tasks);
                await Promise.all(tasks.map(async (body) => {
                    post("task", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                getData(userId);
                                setTaskUploadModal(false);
                                setFile(null);
                                setTaskFile(null);
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                }))
            }
        } catch (error) {
            console.error('Error reading Excel file:', error);
        }
    }

    const handleDupliProj = (row) => {
        setCurrentData(row);
        setCurrentTask({ ...row?.tasks, _id: undefined });
        setFile(row?.tasks?.courierSlip);
        setTaskModal(true);
    }

    const handleUpdateProj = (row) => {
        console.log("upadting task ", row?.tasks);
        setCurrentData(row);
        setCurrentTask(row?.tasks);
        setNoBook(row?.tasks?.books);
        setFile(row?.tasks?.courierSlip);
        setSubmissions(row?.tasks?.submissions);
        setTaskModal(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentTask({ ...row, actionType: "Delete" });
        setCurrentData(row);
        setConfirm(true);
    }

    const handleValidSubmitAmount = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: currentData?._id,
                taskId: currentData?.tasks?._id,
                type: "Credit",
                status: "Approved",
                token: user?.token
            }
            post("wallet", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setMoneyModal(false);
                        getData(userId);
                    } else
                        toast.error("" + res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while updating data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleDownload = async (item, index) => {
        try {
            const response = await fetch(item?.doc, {
                mode: 'cors',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.setAttribute('download', item?.bookName + ".doc");
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(objectUrl); // Free up memory when done
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const onConfirm = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                taskId: currentData?.tasks?._id,
                subId,
                status: currentData?.to,
                remark: v?.remark,
                token: user?.token
            }
            if (currentData?.actionType == 'Task') {
                put("task/sub", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData(userId);
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentTask?.actionType == 'Delete') {
                del("task", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData(userId)
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleStatus = (item, to) => {
        setCurrentData({ ...currentData, actionType: "Task", to })
        setSubId(item?._id);
        setConfirm(true);
    }

    const checkApproved = () => {
        let check = true;
        if (!currentData?.submittedCount)
            check = true;
        // currentData?.tasks?.submissions?.forEach(element => {
        //     if (element?.status == "Pending" || element?.status == "Rejected")
        //         check = false;
        // });
        return check;
    }

    const getOrdinalSuffix = (number) => {
        const j = number % 10;
        const k = number % 100;
        if (j === 1 && k !== 11) return `${number}st Week`;
        if (j === 2 && k !== 12) return `${number}nd Week`;
        if (j === 3 && k !== 13) return `${number}rd Week`;
        return `${number}th Week`;
    };

    const handleSearch = (str) => {
        if (!loading) {
            setLoading(true);
            get("user/list?search=" + str, { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setUsers(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const onProgress = (p) => {
        console.log(p);
    }

    // Generate an array of numbers from 1 to 36
    const numbers = Array.from({ length: 36 }, (_, index) => index + 1);

    const items = numbers.map((number) => {
        // Convert number to corresponding week string
        const weekString = getOrdinalSuffix(number);
        // Find the task for the current week
        const task = data.find(d => d.tasks.week === weekString);
        const circleClass = task ? 'progress-circle active' : 'progress-circle';
        const text = task ? 'active' : '';
        // console.log("task", task)
        return (
            <li key={number} className='task-check-box'>
                <div className={circleClass} style={{
                    backgroundColor:
                        task?.tasks?.status == "Pending" ? "#f59d18" :
                            task?.tasks?.status == "Submitted" ? Utils.themeColor :
                                task?.tasks?.status == "Rejected" ? "#f51818" :
                                    task?.tasks?.status == "Approved" ? task?.tasks?.amount > 0 ? Utils.themeColorDark : "#a655a3" : "#a2a2a3"
                }}>
                    {task ? (
                        <img src={require('../../assets/images/check.png')} alt="Checked" />
                    ) : (
                        <></>
                    )}
                </div>
                <h5 className={text}>{getOrdinalSuffix(number)}</h5>
            </li>
        );
    });

    const handleBookName = (val, to, key) => {
        // console.log("chaning book array", val, to, key);
        let temp = [...submissions];
        let updatedSubmission = { ...temp[to] };
        updatedSubmission[key] = val;
        temp[to] = updatedSubmission;
        setSubmissions(temp);
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }
    console.log('current data on task', currentTask);

    return (
        <React.Fragment>
            <Header />
            <Loader visible={loading} />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?._id}
            />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentTask ? currentTask : currentData}
            />
            <Modal isOpen={taskUploadModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>{currentTask?._id ? 'Update work for' : 'Assign work to'} {currentData?.name}</b></h5></CardTitle>
                    <CardBody>
                        <AvForm onValidSubmit={handleValidUpload}>
                            <Row>

                                <Col lg={6}>
                                    <div className='mt-4'>
                                        <AvField
                                            name="courierBy"
                                            type="text"
                                            label="Courier Company *"
                                            placeholder="Enter the courier company name"
                                            required
                                            value={currentTask?.courierBy}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-4'>
                                        <AvField
                                            name="courierNumber"
                                            type="text"
                                            label="Consignment Number *"
                                            placeholder="Enter the consignment number"
                                            required
                                            value={currentTask?.courierNumber}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="courierStatus"
                                            type="select"
                                            label="Consignment Status *"
                                            placeholder="Enter the consignment statu"
                                            required
                                            value={currentTask?.courierStatus}
                                        >
                                            <option value="">Select consignment status</option>
                                            <option value="In Transit">In Transit</option>
                                            <option value="Delivered">Delivered</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        {file ?
                                            <div className='img-banner'>
                                                <img src={file} style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                                <div className='img-banner-close' onClick={() => { setCurrentData(null); setFile(null) }}>
                                                    <i className='ri-close-line' />
                                                </div>
                                            </div>
                                            :
                                            <AvField
                                                name="file"
                                                type="file"
                                                label="Consignment Slip *"
                                                accept="image/*"
                                                multiple={false}
                                                onChange={e => setFile(URL.createObjectURL(e.target.files[0]))}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="task"
                                            type="file"
                                            label="Task Docx File *"
                                            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            required
                                            onChange={e => setTaskFile(URL.createObjectURL(e.target.files[0]))}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-4'>
                                    <div className='d-flex justify-content-around mt-4 mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setCurrentTask(null); setTaskUploadModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            Cancel</Button>
                                        <Button type="submit" className="btn-sign"
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            {currentTask?._id ? 'Update' : 'Assign'}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <Modal isOpen={taskModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>{currentTask?._id ? 'Update work for' : 'Assign work to'} {currentData?.name}</b></h5></CardTitle>
                    <CardBody>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <div className='mt-4'>
                                        <AvField
                                            name="courierBy"
                                            type="text"
                                            label="Courier Company *"
                                            placeholder="Enter the courier company name"
                                            required
                                            value={currentTask?.courierBy}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-4'>
                                        <AvField
                                            name="courierNumber"
                                            type="text"
                                            label="Consignment Number *"
                                            placeholder="Enter the consignment number"
                                            required
                                            value={currentTask?.courierNumber}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="courierStatus"
                                            type="select"
                                            label="Consignment Status *"
                                            placeholder="Enter the consignment statu"
                                            required
                                            value={currentTask?.courierStatus == "Received" ? "Delivered" : currentTask?.courierStatus}
                                        >
                                            <option value="">Select consignment status</option>
                                            <option value="In Transit">In Transit</option>
                                            <option value="Delivered">Delivered</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        {file ?
                                            <div className='img-banner'>
                                                <img src={file} style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                                <div className='img-banner-close' onClick={() => { setCurrentData(null); setFile(null) }}>
                                                    <i className='ri-close-line' />
                                                </div>
                                            </div>
                                            :
                                            <AvField
                                                name="file"
                                                type="file"
                                                label="Consignment Slip *"
                                                accept="image/*"
                                                multiple={false}
                                                onChange={e => setFile(URL.createObjectURL(e.target.files[0]))}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="title"
                                            type="text"
                                            label="Task Title *"
                                            placeholder="Enter the title of assignment"
                                            required
                                            value={currentTask?.title ? currentTask?.title : ""}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="week"
                                            type="select"
                                            label="Week *"
                                            required
                                            value={currentTask?.week ? currentTask?.week : ""}
                                        >
                                            <option value="">Select Week</option>
                                            {week?.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className='mt-2'>
                                        <AvField
                                            name="books"
                                            type="number"
                                            label="Number of Books *"
                                            placeholder="Enter the number of books assigned"
                                            required
                                            value={String(noBook)}
                                            onChange={(e) => setNoBook(e.target.value)}
                                            maxLength="2"
                                            validate={{ min: { value: 1, errorMessage: "Min 1 book is required !" }, max: { value: 99, errorMessage: "Max 99 book is required !" } }}
                                        />
                                    </div>
                                </Col>
                                {submissions.map((item, index) => (
                                    <Row>
                                        <Col lg={6}>
                                            <div className='mt-2'>
                                                <AvField
                                                    name={`bookName${index}`}
                                                    type="text"
                                                    label={`Book ${index + 1} Name *`}
                                                    placeholder={`Enter the book ${index + 1} name`}
                                                    required
                                                    value={item?.bookName}
                                                    onChange={(e) => handleBookName(e.target.value, index, "bookName")}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className='mt-2'>
                                                <AvField
                                                    name={"pages" + index}
                                                    type="number"
                                                    label={`Book ${index + 1} Pages *`}
                                                    placeholder={`Enter the book ${index + 1} pages`}
                                                    required
                                                    value={item?.totalPages}
                                                    onChange={(e) => handleBookName(e.target.value, index, "totalPages")}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                                }
                                <Col md={12} className='mt-4'>
                                    <div className='d-flex justify-content-around mt-4 mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setCurrentTask(null); setTaskModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            Cancel</Button>
                                        <Button type="submit" className="btn-sign"
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            {currentTask?._id ? 'Update' : 'Assign'}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <Modal isOpen={moneyModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>Add Amount for {currentData?.tasks?.title} to {currentData?.name}'s wallet</b></h5></CardTitle>
                    <CardBody>
                        <AvForm onValidSubmit={handleValidSubmitAmount}>
                            <Row>
                                {checkApproved() ?
                                    <Row>
                                        <Col md={12}>
                                            <div className='mt-4'>
                                                <AvField
                                                    name="amount"
                                                    type="number"
                                                    label="Amount *"
                                                    placeholder="Enter the amount"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className='mt-2'>
                                                <AvField
                                                    name="remark"
                                                    type="textarea"
                                                    label="Remark *"
                                                    placeholder="Enter the remark"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Col md={12} className='mt-4'>
                                        <CardTitle><h5 className='text-danger text-center mt-4 mb-4'>Some submissions are still Pending / Rejected or not Task Submitted!</h5></CardTitle>
                                    </Col>
                                }
                                <Col md={12} className='mt-2'>
                                    <div className='d-flex justify-content-around mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setMoneyModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            Cancel</Button>
                                        {checkApproved() ?
                                            <Button type="submit" className="btn-sign"
                                                style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                                Add To Wallet</Button>
                                            : null}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <DataTable
                                        columns={walletColumns}
                                        data={walletHistory}
                                        pagination
                                        conditionalRowStyles={[{
                                            when: row => row?.style,
                                            style: row => ({ width: row?.style?.width }),
                                        },
                                        ]}
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: 15,
                                                    width: 0
                                                },
                                            },
                                            cells: {
                                                style: {
                                                    width: 0
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <Modal isOpen={searchModal} style={{ maxWidth: 1000 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>Search User By Name and Mobile Number</b></h5></CardTitle>
                    <CardBody>
                        <div className='search-icon-box' style={{ position: "absolute", right: 10, top: 10 }} onClick={() => setSearchModal(false)}>
                            <button type="button"><i class="ri-close-line" style={{ fontSize: 22 }}></i></button>
                        </div>
                        <AvForm>
                            <Row>
                                <Col md={12}>
                                    <div className='mt-4'>
                                        <AvField
                                            name="search"
                                            type="text"
                                            label="Search"
                                            placeholder="Enter the user name or mobile number"
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <DataTable
                                        columns={userColumns}
                                        data={users}
                                        pagination
                                        conditionalRowStyles={[{
                                            when: row => row?.style,
                                            style: row => ({ width: row?.style?.width }),
                                        },
                                        ]}
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: 15,
                                                    width: 0
                                                },
                                            },
                                            cells: {
                                                style: {
                                                    width: 0
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <Modal isOpen={taskModalList} style={{ maxWidth: 800 }} centered toggle={() => setTaskListModal(false)}>
                <ModalBody className='mx-2'>
                    <Row className='mt-1'>
                        <Col md={6}>
                            <CardTitle><h5><b>{currentData?.tasks?.title}</b></h5></CardTitle>
                        </Col>
                        <Col md={6}>
                            <p style={{ float: 'right', display: 'flex', alignItems: 'center', gap: 5 }}><i class="ri-time-line" style={{ marginTop: 2 }}></i> <b>{moment.tz(currentData?.tasks?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</b></p>
                        </Col>
                    </Row>
                    <CardBody>
                        {currentData?.tasks?.submissions?.map((item, index) => {
                            if (item?.status != "Pending")
                                return (
                                    <Row className='mt-2'>
                                        <Col md={12} style={{ height: 1, backgroundColor: "#807f7d", marginBottom: 20 }}></Col>
                                        <Col md={8}>
                                            <p>{item?.bookName}</p>
                                        </Col>
                                        <Col md={4}>
                                            <p style={{ float: 'right', display: 'flex', alignItems: 'center', gap: 5, }}><i class="ri-time-line" style={{ marginTop: 2 }}></i><b>{moment.tz(item?.updatedAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</b></p>
                                        </Col>
                                        <Col md={4}>
                                            <Row className='mt-2'>
                                                <Col md={6}>
                                                    <div style={{ width: 100, height: 150, border: "1px dotted #1967b9", padding: 4 }}>
                                                        <img src={item?.frontOut} style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ width: 100, height: 150, border: "1px dotted #1967b9", padding: 4 }}>
                                                        <img src={item?.backOut} style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                            <div className='justify-content-center mt-2 mx-5 text-center'>
                                                <h5 style={{ color: Utils.themeColor }}><b><i class="ri-article-line" style={{ marginTop: 2 }}></i> Total Page</b></h5>
                                                <h6 style={{ color: Utils.themeColorDark }}><b>{item?.totalPages}</b></h6>
                                                <h5 style={{ color: Utils.themeColor }}><b><i class="ri-article-line" style={{ marginTop: 2 }}></i> Submitted Pages</b></h5>
                                                <h6 style={{ color: Utils.themeColorDark }}><b>{item?.pages}</b></h6>
                                                {item?.status == "Rejected" ?
                                                    <span className='mt-4'>Rejection Reason <span className='mt-4 text-danger'>{item?.remark}</span></span>
                                                    : null}
                                            </div>
                                        </Col>
                                        <Col md={12} className='mt-4 mb-4'>
                                            <Row>
                                                <Col md={4}>
                                                    <Button type="button"
                                                        onClick={() => handleDownload(item)}
                                                        style={{
                                                            backgroundColor: Utils.themeColor, width: "100%",
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            gap: 5
                                                        }}>
                                                        <i class="ri-file-download-line" style={{ marginTop: 2 }}></i>
                                                        Download</Button>
                                                </Col>
                                                {checkPermission("taskAssignment", "update") ?
                                                    <Col md={4}>
                                                        <Button type="button"
                                                            onClick={() => handleStatus(item, "Approved")}
                                                            disabled={item?.status != "Submitted"}
                                                            style={{
                                                                backgroundColor: Utils.themeColorDark, width: "100%",
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                gap: 5
                                                            }}
                                                            className='btn'>
                                                            <i class="ri-check-line" style={{ marginTop: 2 }}></i>
                                                            {item?.status == "Approved" ? "Approved" : "Approve"}</Button>
                                                    </Col>
                                                    : null}
                                                {checkPermission("taskAssignment", "update") ?
                                                    <Col md={4}>
                                                        <Button type="button" className='btn btn-danger'
                                                            onClick={() => handleStatus(item, "Rejected")}
                                                            disabled={item?.status != "Submitted"}
                                                            style={{
                                                                backgroundColor: "red", width: "100%",
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                gap: 5
                                                            }}>
                                                            <i class="ri-close-line" style={{ marginTop: 2 }}></i>
                                                            {item?.status == "Rejected" ? "Rejected" : "Reject"}</Button>
                                                    </Col>
                                                    : null}
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                        }
                        )}
                    </CardBody>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <AvForm className="mx-3" onValidSubmit={(e, v) => { setUserId(v?.search); getData(v?.search) }}>
                        <Row className='mb-4'>
                            <Col md={4}>
                                {
                                    data?.length ? <CardBody className='card-header-box'>
                                        <div className='profile-img'>
                                            <img src={data[0]?.profile_picture} />
                                        </div>
                                        <div>
                                            <CardTitle><b>{data[0]?.name}</b></CardTitle>
                                            <CardSubtitle>{data[0]?.userId}<code style={{ color: 'green' }}> {data[0]?.plan}</code></CardSubtitle>
                                        </div>
                                    </CardBody>
                                        :
                                        <CardBody className='card-header-box' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <div>
                                                <CardTitle><b>Assign Tasks</b></CardTitle>
                                                <CardSubtitle>Users who have paid &<code> Approved.</code></CardSubtitle>
                                            </div>
                                            <div className='search-icon-box' onClick={() => setSearchModal(true)}>
                                                <button type="button"><i class="ri-search-line"></i></button>
                                            </div>
                                        </CardBody>
                                }
                            </Col>
                            <Col md={8}>
                                {
                                    data?.length ? <div className='task-detail-box'>
                                        <div className='plan-type-img'>
                                            {
                                                data[0]?.plan == 'Beginner' ? <img src={require('../../assets/images/biggenner.png')} /> : null
                                            }
                                            {
                                                data[0]?.plan == 'Intermediate' ? <img src={require('../../assets/images/intermediate.png')} /> : null
                                            }
                                            {
                                                data[0]?.plan == 'Advanced' ? <img src={require('../../assets/images/advance.png')} /> : null
                                            }
                                        </div>
                                        <div className='task-detail-box-1'>
                                            <div>
                                                <p>Total Books Assigned : <b>{data?.reduce((accumulator, item) => accumulator + (item?.tasks?.books || 0), 0)}</b></p>
                                                <p>Total Task Submissions : <b>{data?.reduce((accumulator, item) => accumulator + (item?.submittedCount || 0), 0)}</b></p>
                                            </div>
                                            <h3 style={{ display: 'flex', alignItems: 'center', gap: 5 }}><i class="ri-wallet-2-line"></i> Wallet ₹ {data[0]?.wallet}</h3>
                                        </div>
                                        {checkPermission("taskAssignment", "write") ?
                                            <>
                                                <Button onClick={() => { setCurrentData(data[0]); setTaskUploadModal(true) }}
                                                    title={"Assign"}
                                                >
                                                    <i className="ri-file-line" style={{ fontSize: 20 }}></i>
                                                    <span>Upload Task</span>
                                                </Button>
                                                <Button onClick={() => { setCurrentData(data[0]); setTaskModal(true) }}
                                                    title={"Assign"}
                                                >
                                                    <i className="ri-add-line" style={{ fontSize: 20 }}></i>
                                                    <span>New Task</span>
                                                </Button>
                                            </>
                                            : null}
                                    </div>
                                        :
                                        null
                                }
                            </Col>
                            {
                                data?.length ? <Col className='task-complete' lg={12}>
                                    <ul className='circle-box'>
                                        {items}
                                    </ul>
                                </Col>
                                    :
                                    null
                            }
                            <Col md={8} className='mt-4'>
                                <AvField
                                    name="search"
                                    placeholder="Search user by Cust Id or email"
                                    required
                                    value={userId}
                                // className="search-input"
                                />
                            </Col>
                            <Col md={2} className='mt-4'>
                                <div className='w-100' style={{}}>
                                    <Button type="submit" className="btn-sign" style={{ float: 'right', width: "100%" }}>Search</Button>
                                </div>
                            </Col>
                            <Col md={2} className='mt-4'>
                                <div className='w-100' style={{}}>
                                    <Button type="reset" className="btn-sign" style={{ float: 'right', width: "100%" }}
                                        onClick={() => { setData([]); setUserId(""); }}
                                    >Reset</Button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment >
    )
}
