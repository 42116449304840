import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";

export default function Dashboard() {

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"></li>
                        </ol>
                        <h4 className="main-title mb-0">Welcome to Dashboard</h4>
                    </div>
                </div>
                <div style={{ height: "60vh" }}>

                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}
