import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal, ModalBody
} from "reactstrap";
import * as Utils from "../../Utils";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddForm from "./AddUserForm";
import moment from 'moment'
import { get, put } from '../../helper/api_helper';
import Header from '../../layouts/Header';
import { useSelector } from 'react-redux';
import { AvField, AvForm } from 'availity-reactstrap-validation';


const SubAdmin = () => {
  const user = useSelector(state => state.user);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [image, setImage] = useState(null);
  const [resetPassModal, setResetPassModal] = useState(null);
  const [newPassShow, setNewPassShow] = useState(false);
  const [cPassShow, setCPassShow] = useState(false);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user?.token]);

  useEffect(() => {
    if (!isAddForm)
      setCurrentData(null);
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("user/admin", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Name',
        selector: row => row?.name,
        maxWidth: "220px"
      },
      {
        name: 'Email',
        selector: row => row?.email,
      },
      {
        name: 'Last Active',
        selector: row => moment(row?.lastSeen).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        name: 'Created At',
        selector: row => moment(row.createdAt).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-pencil-fill" style={{ fontSize: 20 }}></i></Button>
          <Button onClick={() => handleUpdatePass(row)}
            title={"Change Password"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-lock-fill" style={{ fontSize: 20 }}></i></Button>
          <Button onClick={() => handleDeleteProj(row, 'user')}
            title={"Ban"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-delete-bin-fill" style={{ fontSize: 20 }}></i></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '200px'
      },
    ]);
  }, [data]);

  const handleUpdateProj = (row) => {
    setImage(row?.image);
    setCurrentData(row);
    setIsAddForm(true);
  }

  const handleUpdatePass = (row) => {
    setCurrentData(row);
    setResetPassModal(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      userId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/user/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      userId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `user`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/lang/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleUpdateValidPass = (e, v) => {
    if (v?.password != v?.cPassword) {
      toast.error("New Password and Confirm Password did not matched!");
      return;
    }
    if (!loading) {
      setLoading(true);
      put("user/password", { token: user?.token, userId: currentData?._id, ...v })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success("" + res?.message);
            setResetPassModal(false);
            getData();
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Header />
      <Loader visible={loading} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
        onCloseClick={() => setDeleteModal(false)}
        actionType={currentData?.actionType}
        data={currentData}
      />
      <Modal isOpen={resetPassModal} centered={true}>
        <ModalBody className="py-3 px-5">
          <AvForm onValidSubmit={handleUpdateValidPass}>
            <Row>
              <Col lg={12}>
                <div>
                  {/* {actionType=='Status' ? 
              <img src={require("../../assets/images/stats.png")} style={{width:200, height:'auto'}} />
            : */}

                  {/* } */}
                  <h4>Update Password For {currentData?.name}</h4>
                </div>
              </Col>
              <Col md={12}>
                <div className='mt-4 position-relative'>
                  <AvField
                    name="password"
                    type={newPassShow ? "text" : "password"}
                    placeholder="Enter the new password"
                    label="New Password *"
                    required
                  />
                  <div className='position-absolute' style={{ top: 32, right: 20, padding: "0px 10px" }} onClick={() => setNewPassShow(!newPassShow)}>
                    <i className={newPassShow ? 'ri-eye-off-line' : 'ri-eye-line'} style={{ fontSize: 20, color: Utils.themeColor }} />
                  </div>
                </div>
                <div className='mt-4 position-relative'>
                  <AvField
                    name="cPassword"
                    type={cPassShow ? "text" : "password"}
                    placeholder="Enter the confirm password"
                    label="Confirm Password *"
                    required
                  />
                  <div className='position-absolute' style={{ top: 32, right: 20, padding: "0px 10px" }} onClick={() => setCPassShow(!cPassShow)}>
                    <i className={cPassShow ? 'ri-eye-off-line' : 'ri-eye-line'} style={{ fontSize: 20, color: Utils.themeColor }} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="text-center mt-5 w-100">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg me-2 w-100"
                    onClick={() => setResetPassModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={6}>
                <div className="text-center mt-5 w-100">
                  <button
                    type="submit"
                    className="btn btn-success btn-lg me-2 w-100"
                  >
                    Reset
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className="main main-app p-3 p-lg-4">
        <Row>
          {isAddForm ?
            <AddForm
              data={currentData}
              loading={loading}
              setLoading={setLoading}
              close={setIsAddForm}
              user={user}
              getData={getData}
            />
            :
            <Col className="col-12">
              <Card>
                <CardBody style={{ backgroundColor: "#fff" }}>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">Sub Admin</CardTitle>
                      <CardSubtitle className="mb-3">
                        Sub Admin Added Before, All the <code>Users</code> will also be able perform <code>actions</code> As per given <code>permissions.</code>
                      </CardSubtitle>
                    </div>
                    {user?.role == 'Admin' &&
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                    }
                  </div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              },
                              cells: {
                                style: {
                                  maxWidth: 50
                                }
                              }
                            }}
                            conditionalRowStyles={[{
                              when: row => row?.seen?.length < 1,
                              style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                            }]}
                          // onRowClicked={(r, e) => seenNoti(r)}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </CardBody>
              </Card>
            </Col>
          }
        </Row>
      </div>
    </React.Fragment>
  )
}

export default SubAdmin;