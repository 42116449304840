import React from "react";
import { Link } from "react-router-dom";
import * as Utils from "../Utils";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; {new Date().getFullYear()}. {Utils?.projectName}. All Rights Reserved.</span>
      <span>Developed by <Link to="https://rajmith.com" target="_blank">RAJMITH</Link></span>
    </div>
  )
}