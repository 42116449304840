import React from "react";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import Signin2 from "../pages/Signin2";
import Signup2 from "../pages/Signup2";
import Logout from "../pages/Logout";

const publicRoutes = [
  { path: "login", element: <Signin2 /> },
  { path: "logout", element: <Logout /> },
  { path: "signup", element: <Signup2 /> },
  { path: "pages/lock", element: <LockScreen /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> }
];

export default publicRoutes;