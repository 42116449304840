import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, Modal, ModalBody, CardTitle, Row, Col } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, download, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import moment from 'moment-timezone';

export default function Genre() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columns2, setColumns2] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [listView, setListView] = useState(false);


    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'Cust Id',
                selector: row => row.userId,
                sortable: true,
                maxWidth: "140px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'email',
                selector: row => row?.email,
                sortable: true
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'Invited',
                selector: row => row?.invited?.length,
                maxWidth: '100px',
                sortable: true
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-line" style={{ fontSize: 20 }}></i></Button>
                    <Button onClick={() => handleListView(row)}
                        title={"List"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-list-check" style={{ fontSize: 20 }}></i></Button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '140px'
            },
        ])
    }, [data]);

    useEffect(() => {
        setColumns2([
            {
                name: 'Cust Id',
                selector: row => row.userId,
                sortable: true,
                maxWidth: "140px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'email',
                selector: row => row?.email,
                sortable: true
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                maxWidth: '130px',
                sortable: true
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setListView(false); setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-line" style={{ fontSize: 20 }}></i></Button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '140px'
            },
        ])
    }, [currentData]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("user/all/invite", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleView = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleListView = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData(row);
            setListView(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                referrerId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Delete') {
                del("referrer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const downloadCsv = () => {
        if (data?.length < 1)
            return;
        if (!loading) {
            setLoading(true);
            download("user/download/invite", { token: user?.token })
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'partner.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?._id}
            />
            <Modal isOpen={listView} className='homebanner-crop' centered={true} style={{ maxWidth: 1000 }} toggle={() => setListView(false)}>
                <ModalBody>
                    <h5 className="text-black font-size-20">Invited users by {currentData?.name}</h5>
                    <div className="py-2">
                        <Row>
                            <Col md={12}>
                                <DataTable
                                    columns={columns2}
                                    data={currentData?.invited}
                                    pagination
                                    conditionalRowStyles={[{
                                        when: row => row?.style,
                                        style: row => ({ width: row?.style?.width }),
                                    },
                                    ]}
                                    customStyles={{
                                        headCells: {
                                            style: {
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 15,
                                                width: 0
                                            },
                                        },
                                        cells: {
                                            style: {
                                                width: 0
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={10}>
                            <CardBody>
                                <CardTitle><b>Users who Invited other users</b></CardTitle>
                                {/* <CardSubtitle>Users you have added before all the users can share the employee Code for invite new <code>users.</code></CardSubtitle> */}
                            </CardBody>
                        </Col>
                        <Col md={2}>
                            <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                <Button type="button" onClick={downloadCsv} className="btn-sign" style={{ float: 'right', width: "100%" }}>Export CSV</Button>
                            </div>
                        </Col>
                        {/* {checkPermission("plans", "write") ?
                            <Col md={1}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={() => { setCurrentData(null); setIsAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                </div>
                            </Col>
                            : null} */}
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}
