import PropTypes from 'prop-types'
import React from "react"
import * as Utils from '../Utils'
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { AvField, AvForm } from 'availity-reactstrap-validation'

const ConfirmModal = (props) => {
  const { show, onConfirm, onCloseClick, data } = props;
  return (
    <Modal isOpen={show} centered={true}>
      <ModalBody className="py-3 px-5">
        <AvForm onValidSubmit={onConfirm}>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                {/* {actionType=='Status' ? 
              <img src={require("../../assets/images/stats.png")} style={{width:200, height:'auto'}} />
            : */}
                <i
                  className="ri-alert-fill"
                  style={{ fontSize: "9em", color: Utils.themeColor }}
                />
                {/* } */}
                <h2>Are you sure?</h2>
                {data?.actionType == 'Status' ?
                  <h4>You want to change the status to {data?.isActive ? 'Inactive' : 'Active'}!</h4>
                  : null}
                {data?.actionType == 'Delete' ?
                  <h4>{"You won't be able to revert this!"}</h4>
                  : null}
                {data?.actionType == 'KYC' ?
                  <h4>You want to change the KYC status to {data?.isKycVerified ? "Disapprove" : "Approve"}!</h4>
                  : null}
                {data?.actionType == 'Recom' ?
                  <h4>You want to change the Recommend status to {data?.isRecom ? "No" : "Yes"}!</h4>
                  : null}
                {data?.actionType == 'PayStatus' ?
                  <h4>You want to change the Payment status to {data?.to}!</h4>
                  : null}
                {data?.actionType == 'Task' ?
                  <h4>You want to change the status to {data?.to}!</h4>
                  : null}
              </div>
            </Col>
            {data?.actionType == 'Task' ?
              <Col md={12}>
                {data?.to == "Rejected" ?
                  <div className='mt-4'>
                    <AvField
                      name="remark"
                      placeholder="Enter the reason"
                      label="Remark (100 words max)*"
                      type={"textarea"}
                      rows={4}
                      maxLength={100}
                    />
                  </div>
                  : null}
              </Col>
              : null}
          </Row>
          <Row>
            <Col md={6}>
              <div className="text-center mt-5 w-100">
                <button
                  type="submit"
                  className="btn btn-success btn-lg me-2 w-100"

                >
                  {data?.actionType == 'Status' ? data?.isActive ? 'Inactive it!' : 'Active it!' : null}
                  {data?.actionType == 'Delete' ? 'Yes, delete it!' : null}
                  {data?.actionType == 'KYC' ? data?.isKycVerified ? 'Disapprove it!' : 'Approve it!' : null}
                  {data?.actionType == 'Recom' ? data?.isRecom ? 'Not Recommend it!' : 'Recommend it!' : null}
                  {data?.actionType == 'PayStatus' ? `Yes, ${data?.to} it!` : null}
                  {data?.actionType == 'Task' ? `Yes, ${data?.to} it!` : null}
                </button>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-center mt-5 w-100">
                <button
                  type="button"
                  className="btn btn-danger btn-lg me-2 w-100"
                  onClick={onCloseClick}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmModal
