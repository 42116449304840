import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, post, put, download } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import Loader from "../../components/Loader";
import moment from "moment-timezone";
import { useLocation, useParams } from 'react-router-dom';

export default function Withdarwal() {
    const location = useLocation();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [profileModal, setProfileModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [moneyModal, setMoneyModal] = useState(false);
    const [subId, setSubId] = useState("");
    const [userId, setUserId] = useState('');
    const [walletHistory, setWalletHistory] = useState([]);
    const [walletColumns, setWalletColumns] = useState([]);
    const [txnStatus, setTxnStatus] = useState("Requested");
    const [receiptModal, setReceiptModal] = useState(false);

    // console.log('location', location)

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    useEffect(() => {
        if (moneyModal) {
            // console.log('currrent data', currentData?.tasks?._id);
            post("wallet/list", { userId: currentData?.userId, type: "Withdraw", status: "Approved", token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setWalletHistory(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }, [moneyModal]);

    useEffect(() => {
        setColumns([
            {
                name: 'Cust Id',
                selector: row => row.custId,
                sortable: true,
                maxWidth: '110px'
            },
            {
                name: 'Cust Name',
                selector: row => row.name,
                sortable: true,
                // maxWidth: '150px',
            },
            {
                name: 'Current Plan',
                selector: row => row?.currentPlan?.title,
                sortable: true,
                maxWidth: '130px',
            },
            {
                name: 'Plan',
                selector: row => row?.title,
                sortable: true,
                maxWidth: '100px',
            },
            {
                name: 'Amount',
                selector: row => row?.paid,
                sortable: true,
                maxWidth: '120px',
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleViewReceipt(row)}
                        title={"Receipt"}
                        className={`btn_status active`}
                    >
                        <i className='ri-image-line' style={{ fontSize: 20 }} />
                    </Button>
                </>,
                name: 'Receipt',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
            {
                cell: (row) => <>
                    <select className='form-select' disabled={!checkPermission("planUpgradeRequest", "update")} value={row?.status} onChange={(e) => handleStatusUpdate(row, e.target.value)}>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approve</option>
                        <option value="Rejected">Reject</option>
                    </select>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '150px'
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setCurrentData({ ...row, txn: true }); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    {/* {
                        checkPermission('city', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('city', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    } */}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
        ])
    }, [data]);

    useEffect(() => {
        setWalletColumns([
            {
                name: 'Amount',
                selector: row => "₹ " + row?.amount,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Type',
                selector: row => row?.type,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Balance',
                selector: row => "₹ " + row?.balance,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Credited At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
            }
        ])
    }, [walletColumns]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("transaction/upgrade", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmit = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: currentData?._id,
                token: user?.token
            }
            post("task", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        getData(currentData?.userId);
                    } else
                        toast.error("" + res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while updating data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmitAmount = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: currentData?.userId,
                status: txnStatus,
                txnId: currentData?._id,
                token: user?.token
            }
            put("wallet/request", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setMoneyModal(false);
                        getData();
                    } else
                        toast.error("" + res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while updating data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleDownload = async (item, index) => {
        try {
            const response = await fetch(item?.doc, {
                mode: 'cors',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.setAttribute('download', item?.bookName + ".doc");
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(objectUrl); // Free up memory when done
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const onConfirm = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                txnId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'PayStatus') {
                body = { ...body, status: currentData?.to }
                put("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData(userId)
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleStatus = (item, to) => {
        setCurrentData({ ...currentData, actionType: "Task", to })
        setSubId(item?._id);
        setConfirm(true);
    }

    const handleStatusUpdate = (row, val) => {
        setCurrentData({ ...row, actionType: "PayStatus", to: val });
        setConfirm(true);
    }

    const handleViewReceipt = (row) => {
        setCurrentData(row);
        setReceiptModal(true);
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const downloadCsv = () => {
        if (data?.length < 1)
            return;
        if (!loading) {
            setLoading(true);
            download("transaction/download/upgrade", { token: user?.token })
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'upgrade.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }
    // console.log('current data on task', currentData);

    return (
        <React.Fragment>
            <Header />
            <Loader visible={loading} />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?.userId}
            />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <Modal isOpen={receiptModal} centered={true} style={{ maxWidth: 800 }} toggle={() => setReceiptModal(false)}>
                <ModalBody className="py-3 px-5">
                    <Row>
                        <Col md={11}>
                        </Col>
                        <Col md={1} className=''>
                            <button
                                type="button"
                                className="btn btn-primary btn-lg d-flex justify-content-center"
                                onClick={() => setReceiptModal(false)}
                                style={{ width: 50 }}
                            >
                                <i className='ri-close-line' style={{ fontSize: 20, fontWeight: 'bold' }} />
                            </button>
                        </Col>
                        <Col lg={12} className='mt-3'>
                            <img src={currentData?.receipt} style={{ width: "100%", height: "auto" }} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={moneyModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>{currentData?.name} requested for withdraw</b></h5></CardTitle>
                    <CardBody>
                        <AvForm onValidSubmit={handleValidSubmitAmount}>
                            <Row>
                                <Row>
                                    <Col md={12}>
                                        <div className='mt-4'>
                                            <AvField
                                                name="amount"
                                                type="number"
                                                label="Amount *"
                                                placeholder="Enter the amount"
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mt-2'>
                                            <AvField
                                                name="utrNo"
                                                type="text"
                                                label="UTR Number *"
                                                placeholder="Enter UTR Number"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mt-2'>
                                            <AvField
                                                name="remark"
                                                type="textarea"
                                                label="Remark *"
                                                placeholder="Enter the remark"
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col md={12} className='mt-2'>
                                    <div className='d-flex justify-content-around mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setMoneyModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "30%" }}>
                                            Cancel</Button>
                                        <Button type="submit" className="btn-sign" onClick={() => setTxnStatus("Rejected")}
                                            style={{ backgroundColor: "#f51818", width: "30%" }}>
                                            Reject Request</Button>
                                        <Button type="submit" className="btn-sign" onClick={() => setTxnStatus("Approved")}
                                            style={{ backgroundColor: Utils.themeColor, width: "30%" }}>
                                            Deduct form wallet</Button>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <DataTable
                                        columns={walletColumns}
                                        data={walletHistory}
                                        pagination
                                        conditionalRowStyles={[{
                                            when: row => row?.style,
                                            style: row => ({ width: row?.style?.width }),
                                        },
                                        ]}
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: 15,
                                                    width: 0
                                                },
                                            },
                                            cells: {
                                                style: {
                                                    width: 0
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <AvForm className="mx-3" onValidSubmit={(e, v) => { setUserId(v?.search); getData(v?.search) }}>
                        <Row className='mb-4'>
                            <Col md={10}>
                                {<CardBody className='card-header-box' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <CardTitle><b>Plan Upgrade Request</b></CardTitle>
                                        <CardSubtitle>Users who have requested for <code> Plan Upgrade.</code></CardSubtitle>
                                    </div>

                                </CardBody>
                                }
                            </Col>
                            <Col md={2}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={downloadCsv} className="btn-sign" style={{ float: 'right' }}>Export CSV</Button>
                                </div>
                            </Col>
                            <Col md={8} className='mt-4'>
                                <AvField
                                    name="search"
                                    placeholder="Search user by Cust Id or email"
                                    required
                                    value={userId}
                                    className="search-input"
                                />
                            </Col>
                            <Col md={2} className='mt-4'>
                                <div className='w-100' style={{}}>
                                    <Button type="submit" className="btn-sign" style={{ float: 'right', width: "100%" }}>Search</Button>
                                </div>
                            </Col>
                            <Col md={2} className='mt-4'>
                                <div className='w-100' style={{}}>
                                    <Button type="reset" className="btn-sign" style={{ float: 'right', width: "100%" }}
                                        onClick={() => { setData([]); setUserId(""); }}
                                    >Reset</Button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment >
    )
}
