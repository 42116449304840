import React, { useEffect, useState, useRef } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Label } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { get, put, uploadS3 } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

export default function Genre() {

    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);
    const cropper = useRef(null);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("setting", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                        setFile(res?.data?.upi?.image);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const updateDetails = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                bank: {
                    ...v
                },
                upi: {
                    ...v
                },
                token: user?.token
            }
            if (file.substring(0, 4) == "blob") {
                const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
                const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
                const temp = await resizeFile(obj);
                const imageRes = await uploadS3("bank", temp, "webp", onProgress);
                body.upi.image = imageRes?.data;
            }
            put("setting", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getData();
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log("error while updating terms", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const onChangeFile = async (e) => {
        // console.log(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const onProgress = (p) => {
        console.log(p);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <AvForm onValidSubmit={updateDetails}>
                        <Row className='mb-4'>
                            <Col md={11}>
                                <CardBody>
                                    <CardTitle><b>Bank Details</b></CardTitle>
                                    <CardSubtitle>All Users will transaction on <code>given details below</code>.</CardSubtitle>
                                </CardBody>
                            </Col>
                            <Row className='px-4'>
                                <Col md={12} className="mt-5">
                                    <h5><b>Bank Details</b></h5>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter your payment title"
                                            required
                                            value={data?.bank?.title}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="bankName"
                                            label="Bank Name *"
                                            placeholder="Enter your bank name"
                                            required
                                            value={data?.bank?.bankName}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="companyName"
                                            label="Company Name *"
                                            placeholder="Enter your company name / Account Holder name"
                                            required
                                            value={data?.bank?.companyName}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="acNumber"
                                            label="Account Number *"
                                            placeholder="Enter your Account Number"
                                            type="number"
                                            required
                                            value={data?.bank?.acNumber}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="ifscCode"
                                            label="IFSC Code *"
                                            placeholder="Enter your bank IFSC Code"
                                            required
                                            value={data?.bank?.ifscCode}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <h5><b>UPI Details</b></h5>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="upiTitle"
                                            label="UPI Title *"
                                            placeholder="Enter your UPI title"
                                            required
                                            value={data?.upi?.upiTitle}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="name"
                                            label="UPI Holder Name *"
                                            placeholder="Enter your UPI holder name"
                                            required
                                            value={data?.upi?.name}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mt-2">
                                        <AvField
                                            name="upiId"
                                            label="UPI ID *"
                                            placeholder="Enter your UPI ID"
                                            required
                                            value={data?.upi?.upiId}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    {file ?
                                        <div className='mt-2' style={{ position: 'relative', width: 500 }}>
                                            <Label>Uri Image *</Label>
                                            {file?.substring(0, 4) == "blob" ?
                                                <Cropper
                                                    style={{ height: 'auto', width: 500 }}
                                                    // aspectRatio={1/1}
                                                    preview=".img-preview"
                                                    guides={true}
                                                    src={file}
                                                    ref={cropper}
                                                />
                                                :
                                                <div style={{ width: 500, position: 'relative' }}>
                                                    <img
                                                        src={file}
                                                        alt=""
                                                        style={{ width: 500, height: 'auto' }}
                                                    // onClick={(e) => imageInput.current.click(e)}
                                                    />
                                                </div>
                                            }
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: 7, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                onClick={() => { setFile(null); }}
                                            >
                                                <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                        </div>
                                        :
                                        <div className="mt-2">
                                            <AvField
                                                name="image"
                                                type="file"
                                                label="QR Image *"
                                                onChange={(e) => onChangeFile(e)}
                                                required
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Col md={12} className='d-flex justify-content-center mt-4'>
                                <Button type="submit" className="btn-sign"
                                    style={{ backgroundColor: Utils.themeColor, width: '20%' }}>
                                    Update</Button>
                            </Col>

                        </Row>
                    </AvForm>
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}
