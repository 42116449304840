import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, download } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import AddForm from './Add';
import * as Utils from "../../Utils";
import moment from 'moment-timezone';

export default function Genre() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [langs, setLangs] = useState([]);
    const [previewModal, setPreviewModal] = useState(false);
    const [userColumns, setUserColumns] = useState([]);
    const [invitedUsers, setInvitedUsers] = useState([]);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'Employee Code',
                selector: row => row.userId,
                sortable: true,
                maxWidth: "130px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'email',
                selector: row => row?.email,
                sortable: true
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                maxWidth: '120px',
                sortable: true
            },
            {
                name: 'Invited',
                selector: row => row?.invited,
                maxWidth: '100px',
                sortable: true
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "170px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleView(row)}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-line" style={{ fontSize: 20 }}></i></Button>
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-edit-fill" style={{ fontSize: 20 }}></i></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></i></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '210px'
            },
        ])
    }, [data]);

    useEffect(() => {
        console.log("currentdata", currentData);
        if (currentData) {
            get("user/invite?userId=" + currentData?.userId, { token: user?.token })
                .then(res => {
                    if (res?.statusCode == 200) {
                        setInvitedUsers(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log("error while getting invited users", err);
                })
        }
    }, [currentData]);

    useEffect(() => {
        setUserColumns([
            {
                name: 'Cust Id',
                selector: row => row.userId,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                cell: (row) => <span>{row?.name}</span>,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'email',
                selector: row => row?.email,
                cell: (row) => <span>{row?.email}</span>,
                sortable: true
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                cell: (row) => <span>{row?.mobile}</span>,
                maxWidth: '110px',
                sortable: true
            },
            {
                name: 'City',
                selector: row => row?.district,
                cell: (row) => <span>{row?.district}</span>,
                maxWidth: '180px',
                sortable: true
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleView(row)}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-line" style={{ fontSize: 20 }}></i></Button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
        ])
    }, [invitedUsers]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("referrer", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateRecomStatus = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Recom" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const handleView = (row) => {
        setCurrentData(row);
        setPreviewModal(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                referrerId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Delete') {
                del("referrer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const downloadCsv = () => {
        if (data?.length < 1)
            return;
        if (!loading) {
            setLoading(true);
            download("referrer/download", { token: user?.token })
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'referrer.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <Modal isOpen={previewModal} style={{ maxWidth: 1200 }} centered toggle={() => setPreviewModal(false)}>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>Invited users by {currentData?.name}</b></h5></CardTitle>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <DataTable
                                    columns={userColumns}
                                    data={invitedUsers}
                                    pagination
                                    conditionalRowStyles={[{
                                        when: row => row?.style,
                                        style: row => ({ width: row?.style?.width }),
                                    },
                                    ]}
                                    customStyles={{
                                        headCells: {
                                            style: {
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 15,
                                                width: 0
                                            },
                                        },
                                        cells: {
                                            style: {
                                                width: 0
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </ModalBody>
            </Modal>
            {!isAdd ?
                <div className="main main-app p-3 p-lg-4">
                    <Card>
                        <Row className='mb-4'>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle><b>Referrer</b></CardTitle>
                                    <CardSubtitle>Users you have added before all the users can share the employee Code for invite new <code>users.</code></CardSubtitle>
                                </CardBody>
                            </Col>
                            <Col md={2}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={downloadCsv} className="btn-sign" style={{ float: 'right', width: "100%" }}>Export CSV</Button>
                                </div>
                            </Col>
                            {checkPermission("plans", "write") ?
                                <Col md={2}>
                                    <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                        <Button type="button" onClick={() => { setCurrentData(null); setIsAdd(true) }} className="btn-sign" style={{ float: 'right', width: "100%" }}>Add</Button>
                                    </div>
                                </Col>
                                : null}
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                            conditionalRowStyles={[{
                                when: row => row?.style,
                                style: row => ({ width: row?.style?.width }),
                            },
                            ]}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        width: 0
                                    },
                                },
                                cells: {
                                    style: {
                                        width: 0
                                    }
                                }
                            }}
                        />
                    </Card>
                    <Footer />
                </div>
                :
                <AddForm
                    user={user}
                    setIsAdd={setIsAdd}
                    loading={loading}
                    setLoading={setLoading}
                    getData={getData}
                    langs={langs}
                    currentData={currentData}
                />
            }
        </React.Fragment>
    )
}
