import React from "react";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import PaidUsers from "../pages/Users/Paid";
import Enquiry from "../pages/Users/Enquiry";
import Plans from "../pages/Plans";
import Terms from "../pages/Terms";
import NewRequest from "../pages/NewRequest";
import Tasks from "../pages/Tasks";
import TaskSubmit from "../pages/TaskSubmit";
import Bank from "../pages/Bank";
import Banner from "../pages/Banner";
import Withdrawal from "../pages/Withdrawal";
import Upgrade from "../pages/Upgrade";
import SubAdmin from "../pages/SubAdmin";
import Referrer from "../pages/Referrer";
import Partner from "../pages/Partner";

const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/users", element: <Users /> },
  { path: "/paid-users", element: <PaidUsers /> },
  { path: "/enquiry", element: <Enquiry /> },
  { path: "/plans", element: <Plans /> },
  { path: "/terms", element: <Terms /> },
  { path: "/new-request", element: <NewRequest /> },
  { path: "/task", element: <Tasks /> },
  { path: "/task-submit", element: <TaskSubmit /> },
  { path: "/bank", element: <Bank /> },
  { path: "/banner", element: <Banner /> },
  { path: "/withdrawal", element: <Withdrawal /> },
  { path: "/upgrade", element: <Upgrade /> },
  { path: "/sub-admin", element: <SubAdmin /> },
  { path: "/referrer", element: <Referrer /> },
  { path: "/partner", element: <Partner /> },
]

export default protectedRoutes;