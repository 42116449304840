import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, post, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import AddForm from './Add';
import * as Utils from "../../Utils";
import moment from 'moment-timezone';

export default function Genre() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [langs, setLangs] = useState([]);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
            },
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Amount',
                selector: row => row?.amount,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'GST %',
                selector: row => row?.gst,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'Points',
                selector: row => row?.bullets?.length,
                maxWidth: '130px',
                sortable: true
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateRecomStatus(row)}
                        title={row?.isRecom ? "No" : "Yes"}
                        className={`btn_status ${row?.isRecom && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isRecom ? 'No' : 'Yes'}
                        </span>
                    </Button>
                </>,
                name: 'Recommanded',
                ignoreRowClick: true,
                maxWidth: '160px'
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [data]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("plan/list", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateRecomStatus = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Recom" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                planId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("plan", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Recom') {
                body = { ...body, isRecom: !currentData?.isRecom }
                put("plan", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("plan", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            {!isAdd ?
                <div className="main main-app p-3 p-lg-4">
                    <Card>
                        <Row className='mb-4'>
                            <Col md={11}>
                                <CardBody>
                                    <CardTitle><b>Plans</b></CardTitle>
                                    <CardSubtitle>Plans you have added before all the users task depends on the <code>plans.</code></CardSubtitle>
                                </CardBody>
                            </Col>
                            {checkPermission("plans", "write") ?
                                <Col md={1}>
                                    <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                        <Button type="button" onClick={() => { setCurrentData(null); setIsAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                    </div>
                                </Col>
                                : null}
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            conditionalRowStyles={[{
                                when: row => row?.style,
                                style: row => ({ width: row?.style?.width }),
                            },
                            ]}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        width: 0
                                    },
                                },
                                cells: {
                                    style: {
                                        width: 0
                                    }
                                }
                            }}
                        />
                    </Card>
                    <Footer />
                </div>
                :
                <AddForm
                    user={user}
                    setIsAdd={setIsAdd}
                    loading={loading}
                    setLoading={setLoading}
                    getData={getData}
                    langs={langs}
                    currentData={currentData}
                />
            }
        </React.Fragment>
    )
}
