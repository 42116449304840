import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, download, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import NotifyModal from '../../components/NotifyModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import moment from 'moment';

export default function Users() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [notifyModal, setNotifyModal] = useState(false);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: '100px'
            },
            {
                name: 'Cust Id',
                selector: row => row.userId,
                sortable: true,
                maxWidth: '100px'
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
                maxWidth: '130px',
            },
            {
                name: 'Email',
                selector: row => row?.email,
                sortable: true
            },
            {
                name: 'Mobile',
                selector: row => row?.mobile,
                sortable: true,
                maxWidth: '130px',
            },
            {
                name: 'Plan',
                selector: row => row?.plan,
                sortable: true,
                maxWidth: '130px',
            },
            {
                name: 'Books Assigned',
                selector: row => row?.tasks ? row?.tasks?.book : "-",
                sortable: true,
                maxWidth: '100px'
            },
            {
                name: 'Registered On',
                selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: '170px',
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <p className="text-white" style={{ fontSize: 16, marginBottom: 0 }}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </p>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
            {
                cell: (row) => <>
                    <Button onClick={() => { setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    <Button onClick={() => handleNotify(row)}
                        title={"Notify"}
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-notification-fill" style={{ fontSize: 20 }}></i></Button>
                    {/*{
                        checkPermission('city', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    } */}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [data]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("user/list", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        if (checkPermission("verifiedUsers", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateKYCStatus = (row) => {
        setCurrentData({ ...row, actionType: "KYC" });
        setConfirm(true);
    }

    const handleNotify = (row) => {
        if (checkPermission("verifiedUsers", "update")) {
            setCurrentData(row);
            setNotifyModal(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                userId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("user", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'KYC') {
                body = { ...body, isKycVerified: !currentData?.isKycVerified }
                put("user", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("user", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const downloadCsv = () => {
        if (data?.length < 1)
            return;
        if (!loading) {
            setLoading(true);
            download("user/download", { token: user?.token })
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'users.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <NotifyModal
                show={notifyModal}
                onCloseClick={() => setNotifyModal(false)}
                data={currentData}
                user={user}
                setLoading={setLoading}
            />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?._id}
                getData={getData}
            />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={10}>
                            <CardBody>
                                <CardTitle><b>Users</b></CardTitle>
                                <CardSubtitle>Users who have registered<code> themselves.</code></CardSubtitle>
                            </CardBody>
                        </Col>
                        <Col md={2}>
                            <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                <Button type="button" onClick={downloadCsv} className="btn-sign" style={{ float: 'right' }}>Export CSV</Button>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}
