import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, post, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function Genre() {

    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [descContent, setDescContent] = useState(null);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("setting", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data?.terms);
                        const contentBlock = htmlToDraft(res?.data?.terms);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            console.log("content block", editorState);
                            setDescContent(editorState);
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setData(html);
        setDescContent(editorState);
    }

    const updateTerms = async () => {
        if (!loading) {
            setLoading(true);
            put("setting", { terms: data, token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log("error while updating terms", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Terms & Conditions</b></CardTitle>
                                <CardSubtitle>All Users need to <code>agree</code> with terms & conditions before purchasing a plan.</CardSubtitle>
                            </CardBody>
                        </Col>
                        <Col lg={12} md={12}>
                            <div className="mt-5">
                                <Editor
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                    editorState={descContent ? descContent : null}
                                    readOnly={!checkPermission("termsAndConditions", "update")}
                                />
                            </div>
                        </Col>
                        {checkPermission("termsAndConditions", "update") ?
                            <Col md={12}>
                                <Button type="button" className="btn-sign"
                                    onClick={updateTerms}
                                    style={{ backgroundColor: Utils.themeColor, float: 'right', marginRight: 50 }}>
                                    Update</Button>
                            </Col>
                            : null}
                    </Row>
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}
