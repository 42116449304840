import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import "./assets/css/remixicon.css";
import "./assets/css/style.css";
import "./scss/style.scss";
import { setUser } from "./store/userSlice";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

function App() {
  const user = useSelector((state) => state.user);
  const [isAuth, setIsAuth] = useState(localStorage.getItem("user"));
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setIsAuth(true);
      dispatch(setUser(JSON.parse(user)));
    }
  }, []);

  useEffect(() => {
    if (user)
      setIsAuth(true);
  }, [user]);

  return (
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter
      // basename="/admin"
      >
        <Routes>
          <Route path="/" element={isAuth ? <Main /> : <Navigate to="/login" />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={isAuth ? route.element : <Navigate to="/login" />}
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
